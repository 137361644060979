@charset "UTF-8";
/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: 3rem; }

.h2,
h2 {
  font-size: 2rem; }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .stocknotification label,
.stocknotification-confirm label, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.search-results .product-grid {
  padding-top: 0; }
  .search-results .product-grid > div:not(.grid-footer) {
    padding: 0;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    @media (max-width: 1199.98px) {
      .search-results .product-grid > div:not(.grid-footer):nth-child(2n) {
        border-right: none; } }

@media (max-width: 1199.98px) and (min-width: 769px) {
  .search-results .product-grid > div:not(.grid-footer):first-child, .search-results .product-grid > div:not(.grid-footer):nth-child(2n + 1) {
    border-left: 1px solid #ddd; } }
    @media (min-width: 992px) and (max-width: 1439.98px) {
      .search-results .product-grid > div:not(.grid-footer):nth-child(3n) {
        border-right: none; } }

@media (min-width: 992px) and (max-width: 1439.98px) and (min-width: 769px) {
  .search-results .product-grid > div:not(.grid-footer):first-child, .search-results .product-grid > div:not(.grid-footer):nth-child(3n + 1) {
    border-left: 1px solid #ddd; } }
    @media (min-width: 1440px) {
      .search-results .product-grid > div:not(.grid-footer):nth-child(4n) {
        border-right: none; } }

@media (min-width: 1440px) and (min-width: 769px) {
  .search-results .product-grid > div:not(.grid-footer):first-child, .search-results .product-grid > div:not(.grid-footer):nth-child(4n + 1) {
    border-left: 1px solid #ddd; } }

.wishlist-list-results .grid-header,
.search-results .grid-header {
  height: auto;
  border-bottom: 1px solid #ddd; }
  @media (min-width: 769px) {
    .wishlist-list-results .grid-header,
    .search-results .grid-header {
      height: 5em; } }
  .wishlist-list-results .grid-header .filter-results,
  .search-results .grid-header .filter-results {
    display: block;
    width: 100%;
    color: #503223;
    height: 50px; }
    .wishlist-list-results .grid-header .filter-results:focus,
    .search-results .grid-header .filter-results:focus {
      -webkit-box-shadow: none;
              box-shadow: none; }
  .wishlist-list-results .grid-header .reset.link,
  .search-results .grid-header .reset.link {
    padding-left: 30px;
    margin-left: 15px;
    border-left: 1px solid #ddd; }
  .wishlist-list-results .grid-header .sort-by:focus,
  .search-results .grid-header .sort-by:focus {
    outline: none; }
  .wishlist-list-results .grid-header .sort-by .selectric-wrapper .selectric,
  .search-results .grid-header .sort-by .selectric-wrapper .selectric {
    border: none; }
  .wishlist-list-results .grid-header .sort-by .selectric-wrapper .label,
  .search-results .grid-header .sort-by .selectric-wrapper .label {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #503223;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer; }
    @media (min-width: 769px) {
      .wishlist-list-results .grid-header .sort-by .selectric-wrapper .label,
      .search-results .grid-header .sort-by .selectric-wrapper .label {
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        text-align: right;
        -moz-text-align-last: right;
             text-align-last: right; } }
  .wishlist-list-results .grid-header .sort-by .selectric-wrapper .selectric-items,
  .search-results .grid-header .sort-by .selectric-wrapper .selectric-items {
    border-color: #ddd;
    max-height: initial; }
    .wishlist-list-results .grid-header .sort-by .selectric-wrapper .selectric-items li,
    .search-results .grid-header .sort-by .selectric-wrapper .selectric-items li {
      font-size: 0.75rem;
      color: #7c655a; }
  .wishlist-list-results .grid-header .sort-by .selectric-wrapper .button,
  .search-results .grid-header .sort-by .selectric-wrapper .button {
    width: 10px; }
  .wishlist-list-results .grid-header .result-count,
  .search-results .grid-header .result-count {
    color: #7c655a; }
  .wishlist-list-results .grid-header .page-size-selector,
  .search-results .grid-header .page-size-selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 1.5em;
    border-left: 1px solid #ddd; }
    .wishlist-list-results .grid-header .page-size-selector input,
    .search-results .grid-header .page-size-selector input {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none; }
    .wishlist-list-results .grid-header .page-size-selector label,
    .search-results .grid-header .page-size-selector label {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin: 0 8px;
      color: #503223;
      opacity: 0.5;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer; }
    .wishlist-list-results .grid-header .page-size-selector input:checked + label,
    .search-results .grid-header .page-size-selector input:checked + label {
      opacity: 1;
      border-bottom: 1px solid currentColor; }

.page-title__seo-description > p {
  margin-bottom: 0; }

.page-title__seo-description a {
  text-decoration: underline; }

.search-results .grid-footer {
  margin: 30px 0; }
  .search-results .grid-footer .btn.more {
    font-weight: 700;
    font-size: 1.25rem;
    width: auto;
    max-width: none; }

/*! nouislider - 14.6.0 - 6/27/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.noUi-target {
  position: relative; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  -webkit-transform-style: flat;
          transform-style: flat; }

.noUi-connect {
  height: 100%;
  width: 100%; }

.noUi-origin {
  height: 10%;
  width: 10%; }

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  -webkit-box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
          box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
          box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
          box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px; }

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px; }

.slider.noUi-target {
  height: 3px;
  border: none;
  margin: 0 10px 45px; }
  .slider.noUi-target .noUi-connect {
    background: #ddd; }
  .slider.noUi-target .noUi-handle {
    width: 18px;
    height: 18px;
    right: calc(-18px / 2);
    top: -8px;
    border: 5px solid #503223;
    border-radius: calc(18px / 2);
    -webkit-box-shadow: none;
            box-shadow: none; }
    .slider.noUi-target .noUi-handle::before, .slider.noUi-target .noUi-handle::after {
      display: none; }
    .slider.noUi-target .noUi-handle:focus {
      outline: none; }
  .slider.noUi-target .slider-labels {
    font-family: "Plantin", serif;
    text-transform: none;
    letter-spacing: 0.01em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 20px;
    color: #7c655a; }
    .slider.noUi-target .slider-labels > .min {
      margin-left: calc(-18px / 2); }
    .slider.noUi-target .slider-labels > .max {
      margin-right: calc(-18px / 2); }

.slider + .no-results-message {
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  opacity: 0; }

.search-results .refinement-bar {
  overflow: auto;
  padding: 15px; }
  .search-results .refinement-bar[style*="display: block"] {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .search-results .refinement-bar ul {
    padding-left: 0;
    margin: 0; }
    .search-results .refinement-bar ul li {
      list-style: none; }
      .search-results .refinement-bar ul li .form-check-label {
        color: #7c655a; }
  @media (max-width: 768.98px) {
    .search-results .refinement-bar {
      background-color: #fff;
      -webkit-box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
              box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
      display: none;
      left: 0;
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 60; } }
  @media (min-width: 769px) {
    .search-results .refinement-bar {
      display: block !important;
      padding: 0; } }
  .search-results .refinement-bar .refinements {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative; }
    .search-results .refinement-bar .refinements .refinement .refinement-header:hover {
      text-decoration: none; }
    .search-results .refinement-bar .refinements .refinement .refinement-header > h5 {
      margin: 0; }
    .search-results .refinement-bar .refinements .refinement .content > li.disabled {
      opacity: 0.4; }
      .search-results .refinement-bar .refinements .refinement .content > li.disabled input,
      .search-results .refinement-bar .refinements .refinement .content > li.disabled label {
        cursor: not-allowed; }
    @media (min-width: 769px) {
      .search-results .refinement-bar .refinements .refinement {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd; } }
    @media (max-width: 768.98px) {
      .search-results .refinement-bar .refinements .refinement {
        padding: 10px 0; }
        .search-results .refinement-bar .refinements .refinement > .content {
          display: none;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto; }
        .search-results .refinement-bar .refinements .refinement .form-check {
          margin-bottom: 10px; }
          .search-results .refinement-bar .refinements .refinement .form-check .form-check-label {
            color: #7c655a; } }
    .search-results .refinement-bar .refinements .refinement .refinement-header .toggle-icon {
      content: "";
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow.svg"); }
      @media (min-width: 769px) {
        .search-results .refinement-bar .refinements .refinement .refinement-header .toggle-icon {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); } }
    @media (min-width: 769px) {
      .search-results .refinement-bar .refinements .refinement .refinement-header[aria-expanded=true] .toggle-icon {
        -webkit-transform: rotate(0);
                transform: rotate(0); } }
    @media (max-width: 768.98px) {
      .search-results .refinement-bar .refinements {
        -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        left: 0; }
        .search-results .refinement-bar .refinements .refinement > .content {
          left: 110%; }
        .search-results .refinement-bar .refinements.filter-open {
          left: -110%; }
          .search-results .refinement-bar .refinements.filter-open .refinement > .content.open {
            display: block; } }
  .search-results .refinement-bar .filter-header {
    margin-bottom: 20px; }
    .search-results .refinement-bar .filter-header .header-bar {
      height: 80px; }
      .search-results .refinement-bar .filter-header .header-bar .close {
        opacity: 1; }
    .search-results .refinement-bar .filter-header .secondary-bar {
      height: 50px;
      border-bottom: 1px solid #ddd; }
  .search-results .refinement-bar .close:focus {
    outline: none; }
  .search-results .refinement-bar .show-results-button span {
    text-transform: none; }

.no-results-block h3 {
  font-weight: bold;
  margin-bottom: 1rem; }

.no-results-block ul {
  padding-left: 0; }
  .no-results-block ul li {
    color: #7c655a;
    list-style: none;
    padding-bottom: 0.5em; }

div.sticky-bottom {
  position: sticky;
  bottom: 0;
  text-align: center;
  background-color: #ffff;
  display: inline-block;
  z-index: 1;
  border-top: #ddd 1px solid;
  margin: 0;
  width: 100%;
  height: 62px; }

.stickyFooterName {
  margin-bottom: 2px;
  font-size: 16px;
  margin-top: 10px; }

p.SFPDescription {
  font-size: 14px;
  line-height: 14px; }

div.sticky-content {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  height: 61px; }

div.message-content-pages {
  height: 60px; }

.noDecor:hover {
  text-decoration: none; }

.stickyClosebutton {
  border: 0;
  background-color: transparent;
  color: #503223;
  cursor: pointer; }

.stickyClosebutton.close-icon {
  margin-top: 2px; }

.stickyClosebutton:focus {
  outline: none; }

.SFPName {
  font-family: flama, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #503223;
  font-size: 0.875rem;
  font-weight: 700; }

.SFPImage {
  width: 57px;
  height: 57px; }

@media only screen and (max-width: 600px) {
  div.sticky-bottom {
    height: 67px; }
  .stickyClosebutton {
    color: #503223;
    right: 0; }
  .closeButton {
    top: -40px;
    position: absolute;
    background-color: transparent;
    padding-left: 4px; }
  .lastCol {
    margin-left: 25px; }
  .SFPImage {
    width: 57px;
    height: 57px;
    margin-right: 22px;
    margin-left: 20px;
    position: relative;
    bottom: -2px; }
  .SFPLink {
    padding-left: 12px;
    padding-right: 12px; }
  div.message-content-pages {
    height: 45px; } }

/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .stocknotification label,
.stocknotification-confirm label, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (min-width: 1440px) and (max-width: 1679.98px) {
  .pull-left-xxl {
    float: left; }
  .pull-right-xxl {
    float: right; } }

@media (min-width: 1680px) {
  .pull-left-xxxl {
    float: left; }
  .pull-right-xxxl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-xl-down {
    display: none !important; } }

@media (max-width: 1679.98px) {
  .hidden-xxl-down {
    display: none !important; } }

.hidden-xxxl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #19b200;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #19b200;
  border-radius: 0.69em;
  background-color: #19b200;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #821e91), color-stop(25%, #821e91), color-stop(25%, #edd134), color-stop(50%, yellow), color-stop(50%, #edd134), color-stop(50%, #59ba00), color-stop(76%, #59ba00), color-stop(76%, #111), to(#111)), -webkit-gradient(linear, left bottom, left top, color-stop(0, #0e5cd1), color-stop(50%, #0e5cd1), color-stop(50%, #e20b0b), to(#e20b0b));
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg); }

.box-content-title {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .box-content-title {
      font-size: 1.5rem; } }

.box-content .icon-box {
  width: 30px;
  height: 30px; }

.box-content .item-detail {
  background-color: #efefef;
  padding: 3rem 0;
  position: relative; }
  .box-content .item-detail .praline-content {
    padding: 1.5rem 0;
    margin: 1.5rem 0;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd; }
  .box-content .item-detail::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-bottom: solid 20px #efefef;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent; }

.box-content > .carousel .glider-track {
  margin: 0 auto 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.box-content .js-praline-item {
  cursor: pointer; }

.box-content .cross-section-carousel {
  width: 445px;
  max-width: 100%; }
  .box-content .cross-section-carousel .glider {
    scrollbar-width: none; }
    .box-content .cross-section-carousel .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }

.box-content .cross-section-carousel-prl .glider {
  scrollbar-width: none; }
  .box-content .cross-section-carousel-prl .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

@media (max-width: 1199.98px) {
  .box-content .cross-section-carousel-prl .carousel-control {
    width: 20px; } }

.box-content .cross-section-carousel-prl a.glider-slide {
  margin-right: 0.5rem;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.box-content .other-boxes-carousel {
  padding: 0 24px; }
  @media (min-width: 769px) {
    .box-content .other-boxes-carousel {
      padding: 0 40px;
      margin: 0 -40px; } }

.box-content .praline-img {
  max-width: 80%; }
  @media (min-width: 769px) {
    .box-content .praline-img {
      max-width: 100%; } }

/* Colors Neuhaus */
/* Fonts Neuhaus */
.carousel .carousel-control {
  position: absolute;
  z-index: 0;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: calc(50% - 20px); }
  @media (min-width: 769px) {
    .carousel .carousel-control {
      width: 40px;
      height: 40px; } }
  .carousel .carousel-control, .carousel .carousel-control.disabled {
    opacity: 1; }
  .carousel .carousel-control, .carousel .carousel-control:hover {
    padding: 0; }

.carousel .pd-carousel-indicators {
  position: static; }

.carousel-inner {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem; }
  @media (min-width: 769px) {
    .carousel-inner {
      padding-bottom: 2rem; } }

.carousel .glider img {
  opacity: 0; }

.carousel .glider .glider-track img {
  opacity: 1; }

.pd-carousel-container {
  margin: 0 auto;
  width: calc(100% - 80px); }
  .pd-carousel-container .carousel-control-prev {
    left: -40px; }
  .pd-carousel-container .carousel-control-next {
    right: -40px; }

@media (max-width: 768.98px) {
  .btn-notify {
    width: 100%; } }

.stocknotification,
.stocknotification-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(45, 45, 45, 0.75);
  z-index: 20; }
  .stocknotification label,
  .stocknotification-confirm label {
    color: #7c655a; }

.AddToCart-overlay-container {
  background-color: rgba(45, 45, 45, 0.75);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }
  .AddToCart-overlay-container .product-image {
    max-width: 100px; }

.pdp-recom .recommendations {
  margin: 2rem 0; }
  @media (min-width: 544px) {
    .pdp-recom .recommendations {
      margin: 3rem 0; } }

.pdp-recom .einstein-title {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .pdp-recom .einstein-title {
      font-size: 1.5rem; } }

.product-detail .attributes .swatch-circle {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 0.313em;
  position: relative; }
  .product-detail .attributes .swatch-circle.color-value[data-selected=true]::after {
    color: #000;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    left: 0.295em;
    position: absolute; }
  .product-detail .attributes .swatch-circle.color-value.selected::after {
    background: #fff;
    border-radius: 50%;
    color: #000;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    height: 0.75em;
    left: 0.31em;
    line-height: 0.8em;
    position: absolute;
    top: 0.35em;
    width: 0.8em; }
  .product-detail .attributes .swatch-circle i.fa-times-circle {
    background: #fff;
    border-radius: 50%;
    height: 0.75em;
    line-height: 0.8em;
    width: 0.8em; }

.product-detail [disabled] .swatch-circle,
.product-detail .swatch-circle.unselectable {
  cursor: not-allowed; }
  .product-detail [disabled] .swatch-circle.color-value.selected::after,
  .product-detail .swatch-circle.unselectable.color-value.selected::after {
    background-color: #495057; }

label.color ~ a:hover {
  text-decoration: none; }

.description-and-detail {
  margin-top: 2em; }
  @media (max-width: 543.98px) {
    .description-and-detail {
      margin-top: 0; }
      .description-and-detail .title {
        font-size: 1.5rem;
        margin-bottom: 0; }
      .description-and-detail > div:last-child {
        margin-bottom: 1em; } }
  @media (min-width: 544px) {
    .description-and-detail .description {
      margin-bottom: 2em; } }
  @media (max-width: 543.98px) {
    .description-and-detail .content {
      margin-top: 1em; } }

.social-container {
  padding-top: 0.9375em;
  text-align: center; }
  .social-container .social-icons {
    text-decoration: none;
    font-size: 1.875em;
    list-style-type: none;
    padding: 0;
    color: #444; }
    @media (max-width: 991.98px) {
      .social-container .social-icons {
        font-size: 1.5625em; } }
    .social-container .social-icons i,
    .social-container .social-icons a {
      color: #444;
      padding-right: 0.2em; }

label.availability {
  padding-right: 0.3125em; }

@media (min-width: 544px) {
  .product-number-rating {
    border-bottom: 1px solid #ccc; } }

@media (min-width: 769px) {
  .product-number-rating {
    border-top: 1px solid #ccc; } }

@media (min-width: 544px) {
  .product-number-rating .ratings {
    margin-top: 0.625em; } }

@media (max-width: 543.98px) {
  .product-number-rating .ratings {
    background-color: #fff;
    margin-top: -1.875em;
    /* 30/16 */
    margin-right: -0.9375em;
    /* 15/16 */
    padding: 0.1875em;
    /* 3/16 */ } }

.product-number {
  color: #ccc;
  font-size: 0.875em;
  padding-bottom: 0.938em;
  padding-top: 0.938em; }

.promotions {
  font-weight: normal;
  text-align: center;
  height: 85%; }
  .promotions div.collapsible-xl button.callout {
    font-size: 1.25em;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    color: #b14350; }
    @media (min-width: 544px) {
      .promotions div.collapsible-xl button.callout {
        font-size: 1.375em; } }
    .promotions div.collapsible-xl button.callout::after {
      color: #000; }

.product-options {
  margin-top: 1.25em; }

.size-chart .size-chart-collapsible {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0, 1);
          transition-timing-function: cubic-bezier(0, 1, 0, 1); }
  .size-chart .size-chart-collapsible.active {
    max-height: 100%;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out; }

.color-attribute {
  border: none;
  padding: 0;
  background: none; }

.non-input-label {
  display: block;
  margin-bottom: 0.5rem; }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0.938em; }
    .product-grid .col-6 {
      padding-left: 5px;
      padding-right: 5px; } }

/* CUSTOM CSS */
.product-detail .price {
  text-align: left;
  font-size: 1.5rem;
  color: #cf7c58; }
  .product-detail .price .value {
    font-weight: 400; }

.product-detail .product-tile .price .value,
.product-detail .product-tile .pdp-link a {
  font-size: 1rem; }

.product-detail .product-tile .image-container {
  margin-bottom: 1rem; }
  .product-detail .product-tile .image-container a .tile-image {
    min-height: auto; }

.product-info-container {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 544px) {
    .product-info-container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd; } }
  .product-info-container .delivery-time {
    padding: 1rem 0; }
    @media (min-width: 544px) {
      .product-info-container .delivery-time {
        border-left: 1px solid #ddd;
        padding-left: 1.5rem;
        margin-left: 1.5rem; } }
  .product-info-container .availability {
    padding: 1rem 0;
    margin: 0; }
  .product-info-container .price-and-availability {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    @media (min-width: 544px) {
      .product-info-container .price-and-availability {
        border: none; } }
    .product-info-container .price-and-availability .prices {
      border-right: 1px solid #ddd;
      padding: 1rem 1.5rem 1rem 0;
      margin-right: 1.5rem; }

.availability-msg {
  margin-bottom: 0; }
  .availability-msg .in-stock {
    color: #19b200;
    position: relative;
    padding-left: 18px; }
    .availability-msg .in-stock::before {
      content: "";
      background-color: #19b200;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      position: absolute;
      top: calc(50% - 5px);
      left: 0; }
  .availability-msg .out-of-stock {
    color: #b14350; }

.btn-product-collapsed {
  border-top: 1px solid #ddd;
  padding: 1.5rem 0; }

.product-links {
  border-top: 1px solid #ddd; }

.pdp-img-subslider .subslider-item {
  border: 1px solid #ddd;
  margin: 1rem; }
  .pdp-img-subslider .subslider-item.selected {
    border-color: #a5a5a5; }

.pdp-img-subslider .glider {
  scrollbar-width: none; }
  .pdp-img-subslider .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

.pdp-main-image {
  max-width: 575px; }
  .pdp-main-image .glider {
    scrollbar-width: none; }
    .pdp-main-image .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .pdp-main-image:hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }

.zoom-glider .image-wrapper,
.mainslider-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .zoom-glider .image-wrapper .personalisation-txt-overlay,
  .mainslider-item .personalisation-txt-overlay {
    position: absolute;
    padding: 0 1rem;
    max-width: var(--persPosWidth);
    top: var(--persPosTop);
    color: #cf7c58;
    font-size: 100%; }
    @media (min-width: 544px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 210%; } }
    @media (min-width: 769px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 100%; } }
    @media (min-width: 992px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 210%; } }

.zoom-glider .image-wrapper .personalisation-txt-overlay {
  max-width: 60%; }

.pdp-img-subslider {
  max-width: 475px; }

.js-praline-matcher .glider {
  scrollbar-width: none; }
  .js-praline-matcher .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

.pdp-image-zoom {
  position: fixed;
  background: #fff;
  width: 100vw;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  padding-top: 40px;
  padding-bottom: 40px; }
  .pdp-image-zoom .glider {
    scrollbar-width: none; }
    .pdp-image-zoom .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .pdp-image-zoom .icon-cross {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .pdp-image-zoom .carousel {
    height: calc(100% - 4rem); }
    .pdp-image-zoom .carousel .glider,
    .pdp-image-zoom .carousel .glider-track,
    .pdp-image-zoom .carousel .glider-slide,
    .pdp-image-zoom .carousel .image-wrapper,
    .pdp-image-zoom .carousel img {
      height: 100%; }
    .pdp-image-zoom .carousel img {
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain; }

ul.usp-items {
  list-style: none; }

ul.usp-items li::before {
  content: "✓";
  color: #19b200;
  font-weight: bold; }

.plp-recom .recommendations {
  margin: 2rem 0;
  padding-top: 2rem;
  border-top: 1px solid #ddd; }
  @media (min-width: 544px) {
    .plp-recom .recommendations {
      margin: 3rem 0;
      padding-top: 0;
      border: none; } }
  @media (min-width: 769px) {
    .plp-recom .recommendations {
      margin: 128px 0 93px; } }

.plp-recom .einstein-title {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .plp-recom .einstein-title {
      font-size: 1.5rem; } }

.mobile-active-filter {
  background-color: #efefef; }
